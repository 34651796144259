import React from 'react';
import { FaReact, FaAngular, FaHtml5, FaNodeJs, FaCss3, FaVuejs, FaBootstrap, FaJsSquare, FaSass } from 'react-icons/fa';
import { TbBrandNextjs } from "react-icons/tb";
import { SiMicrosoftsharepoint } from 'react-icons/si';
import { FaTasks, FaMagic } from 'react-icons/fa';
import { GiPowerLightning } from 'react-icons/gi';
import { SiMongodb } from 'react-icons/si';

const VideoCard = () => {
  return (
    <section className="video-section">
      <div className="video-card">
      <video className="video" src="/video.mp4" controls loop muted  style={{maxHeight:'491px'}}/>
      </div>
    </section>
  );
};

const Card = () => {
  return (
    <div style={{backgroundColor:'#ffffff',marginTop:'-25px'}}>
    <div className='wow fadeInUp' style={{display:'flex',justifyContent:'center',backgroundColor:'white'}}>
      <VideoCard/>
    </div>

    <div id="scroller">
     
      <div id="scroller-in">
      <h4><span className="centreAlign"><SiMicrosoftsharepoint /> SharePoint</span></h4>
      <h4><span className="centreAlign"><FaTasks /> Power Automate</span></h4>
      <h4><span className="centreAlign"><FaMagic /> PowerApps</span></h4>
      <h4><span className="centreAlign"><GiPowerLightning /> Power Platform</span></h4>
      <h4><span className="centreAlign"><SiMongodb /> MERN Stack</span></h4>
        <h4><span className="centreAlign"><FaReact /> React</span></h4>
        <h4><span className="centreAlign"><TbBrandNextjs /> Next.js</span></h4>
        <h4><span className="centreAlign"><FaAngular /> Angular</span></h4>
        <h4><span className="centreAlign"><FaHtml5 /> HTML</span></h4>
        <h4><span className="centreAlign"><FaNodeJs /> Node.js</span></h4>
        <h4><span className="centreAlign"><FaCss3 /> CSS</span></h4>
        <h4><span className="centreAlign"><FaVuejs /> Vue.js</span></h4>
        <h4><span className="centreAlign"><FaBootstrap /> Bootstrap</span></h4>
        <h4><span className="centreAlign"><FaJsSquare /> JavaScript</span></h4>
        <h4><span className="centreAlign"><FaSass /> Sass</span></h4>
        <h4><span className="centreAlign"><SiMicrosoftsharepoint /> SharePoint</span></h4>
      <h4><span className="centreAlign"><FaTasks /> Power Automate</span></h4>
      <h4><span className="centreAlign"><FaMagic /> PowerApps</span></h4>
      <h4><span className="centreAlign"><GiPowerLightning /> Power Platform</span></h4>
      <h4><span className="centreAlign"><SiMongodb /> MERN Stack</span></h4>
        <h4><span className="centreAlign"><FaReact /> React</span></h4>
        <h4><span className="centreAlign"><TbBrandNextjs /> Next.js</span></h4>
        <h4><span className="centreAlign"><FaAngular /> Angular</span></h4>
        <h4><span className="centreAlign"><FaHtml5 /> HTML</span></h4>
        <h4><span className="centreAlign"><FaNodeJs /> Node.js</span></h4>
        <h4><span className="centreAlign"><FaCss3 /> CSS</span></h4>
        <h4><span className="centreAlign"><FaVuejs /> Vue.js</span></h4>
        <h4><span className="centreAlign"><FaBootstrap /> Bootstrap</span></h4>
        <h4><span className="centreAlign"><FaJsSquare /> JavaScript</span></h4>
        <h4><span className="centreAlign"><FaSass /> Sass</span></h4>
        <h4><span className="centreAlign"><SiMicrosoftsharepoint /> SharePoint</span></h4>
      <h4><span className="centreAlign"><FaTasks /> Power Automate</span></h4>
      <h4><span className="centreAlign"><FaMagic /> PowerApps</span></h4>
      <h4><span className="centreAlign"><GiPowerLightning /> Power Platform</span></h4>
      <h4><span className="centreAlign"><SiMongodb /> MERN Stack</span></h4>
        <h4><span className="centreAlign"><FaReact /> React</span></h4>
        <h4><span className="centreAlign"><TbBrandNextjs /> Next.js</span></h4>
        <h4><span className="centreAlign"><FaAngular /> Angular</span></h4>
        <h4><span className="centreAlign"><FaHtml5 /> HTML</span></h4>
        <h4><span className="centreAlign"><FaNodeJs /> Node.js</span></h4>
        <h4><span className="centreAlign"><FaCss3 /> CSS</span></h4>
        <h4><span className="centreAlign"><FaVuejs /> Vue.js</span></h4>
        <h4><span className="centreAlign"><FaBootstrap /> Bootstrap</span></h4>
        <h4><span className="centreAlign"><FaJsSquare /> JavaScript</span></h4>
        <h4><span className="centreAlign"><FaSass /> Sass</span></h4>
        <h4><span className="centreAlign"><SiMicrosoftsharepoint /> SharePoint</span></h4>
      <h4><span className="centreAlign"><FaTasks /> Power Automate</span></h4>
      <h4><span className="centreAlign"><FaMagic /> PowerApps</span></h4>
      <h4><span className="centreAlign"><GiPowerLightning /> Power Platform</span></h4>
      <h4><span className="centreAlign"><SiMongodb /> MERN Stack</span></h4>
        <h4><span className="centreAlign"><FaReact /> React</span></h4>
        <h4><span className="centreAlign"><TbBrandNextjs /> Next.js</span></h4>
        <h4><span className="centreAlign"><FaAngular /> Angular</span></h4>
        <h4><span className="centreAlign"><FaHtml5 /> HTML</span></h4>
        <h4><span className="centreAlign"><FaNodeJs /> Node.js</span></h4>
        <h4><span className="centreAlign"><FaCss3 /> CSS</span></h4>
        <h4><span className="centreAlign"><FaVuejs /> Vue.js</span></h4>
        <h4><span className="centreAlign"><FaBootstrap /> Bootstrap</span></h4>
        <h4><span className="centreAlign"><FaJsSquare /> JavaScript</span></h4>
        <h4><span className="centreAlign"><FaSass /> Sass</span></h4>
        <h4><span className="centreAlign"><SiMicrosoftsharepoint /> SharePoint</span></h4>
      <h4><span className="centreAlign"><FaTasks /> Power Automate</span></h4>
      <h4><span className="centreAlign"><FaMagic /> PowerApps</span></h4>
      <h4><span className="centreAlign"><GiPowerLightning /> Power Platform</span></h4>
      <h4><span className="centreAlign"><SiMongodb /> MERN Stack</span></h4>
      </div>
    </div>

    <div id="scroller">
     
      <div id="scrollerReverse-in">
      <h4><span className="centreAlign"><SiMicrosoftsharepoint /> SharePoint</span></h4>
      <h4><span className="centreAlign"><FaTasks /> Power Automate</span></h4>
      <h4><span className="centreAlign"><FaMagic /> PowerApps</span></h4>
      <h4><span className="centreAlign"><GiPowerLightning /> Power Platform</span></h4>
      <h4><span className="centreAlign"><SiMongodb /> MERN Stack</span></h4>
        <h4><span className="centreAlign"><FaReact /> React</span></h4>
        <h4><span className="centreAlign"><TbBrandNextjs /> Next.js</span></h4>
        <h4><span className="centreAlign"><FaAngular /> Angular</span></h4>
        <h4><span className="centreAlign"><FaHtml5 /> HTML</span></h4>
        <h4><span className="centreAlign"><FaNodeJs /> Node.js</span></h4>
        <h4><span className="centreAlign"><FaCss3 /> CSS</span></h4>
        <h4><span className="centreAlign"><FaVuejs /> Vue.js</span></h4>
        <h4><span className="centreAlign"><FaBootstrap /> Bootstrap</span></h4>
        <h4><span className="centreAlign"><FaJsSquare /> JavaScript</span></h4>
        <h4><span className="centreAlign"><FaSass /> Sass</span></h4>
        <h4><span className="centreAlign"><SiMicrosoftsharepoint /> SharePoint</span></h4>
      <h4><span className="centreAlign"><FaTasks /> Power Automate</span></h4>
      <h4><span className="centreAlign"><FaMagic /> PowerApps</span></h4>
      <h4><span className="centreAlign"><GiPowerLightning /> Power Platform</span></h4>
      <h4><span className="centreAlign"><SiMongodb /> MERN Stack</span></h4>
        <h4><span className="centreAlign"><FaReact /> React</span></h4>
        <h4><span className="centreAlign"><TbBrandNextjs /> Next.js</span></h4>
        <h4><span className="centreAlign"><FaAngular /> Angular</span></h4>
        <h4><span className="centreAlign"><FaHtml5 /> HTML</span></h4>
        <h4><span className="centreAlign"><FaNodeJs /> Node.js</span></h4>
        <h4><span className="centreAlign"><FaCss3 /> CSS</span></h4>
        <h4><span className="centreAlign"><FaVuejs /> Vue.js</span></h4>
        <h4><span className="centreAlign"><FaBootstrap /> Bootstrap</span></h4>
        <h4><span className="centreAlign"><FaJsSquare /> JavaScript</span></h4>
        <h4><span className="centreAlign"><FaSass /> Sass</span></h4>
        <h4><span className="centreAlign"><SiMicrosoftsharepoint /> SharePoint</span></h4>
      <h4><span className="centreAlign"><FaTasks /> Power Automate</span></h4>
      <h4><span className="centreAlign"><FaMagic /> PowerApps</span></h4>
      <h4><span className="centreAlign"><GiPowerLightning /> Power Platform</span></h4>
      <h4><span className="centreAlign"><SiMongodb /> MERN Stack</span></h4>
        <h4><span className="centreAlign"><FaReact /> React</span></h4>
        <h4><span className="centreAlign"><TbBrandNextjs /> Next.js</span></h4>
        <h4><span className="centreAlign"><FaAngular /> Angular</span></h4>
        <h4><span className="centreAlign"><FaHtml5 /> HTML</span></h4>
        <h4><span className="centreAlign"><FaNodeJs /> Node.js</span></h4>
        <h4><span className="centreAlign"><FaCss3 /> CSS</span></h4>
        <h4><span className="centreAlign"><FaVuejs /> Vue.js</span></h4>
        <h4><span className="centreAlign"><FaBootstrap /> Bootstrap</span></h4>
        <h4><span className="centreAlign"><FaJsSquare /> JavaScript</span></h4>
        <h4><span className="centreAlign"><FaSass /> Sass</span></h4>
        <h4><span className="centreAlign"><SiMicrosoftsharepoint /> SharePoint</span></h4>
      <h4><span className="centreAlign"><FaTasks /> Power Automate</span></h4>
      <h4><span className="centreAlign"><FaMagic /> PowerApps</span></h4>
      <h4><span className="centreAlign"><GiPowerLightning /> Power Platform</span></h4>
      <h4><span className="centreAlign"><SiMongodb /> MERN Stack</span></h4>
        <h4><span className="centreAlign"><FaReact /> React</span></h4>
        <h4><span className="centreAlign"><TbBrandNextjs /> Next.js</span></h4>
        <h4><span className="centreAlign"><FaAngular /> Angular</span></h4>
        <h4><span className="centreAlign"><FaHtml5 /> HTML</span></h4>
        <h4><span className="centreAlign"><FaNodeJs /> Node.js</span></h4>
        <h4><span className="centreAlign"><FaCss3 /> CSS</span></h4>
        <h4><span className="centreAlign"><FaVuejs /> Vue.js</span></h4>
        <h4><span className="centreAlign"><FaBootstrap /> Bootstrap</span></h4>
        <h4><span className="centreAlign"><FaJsSquare /> JavaScript</span></h4>
        <h4><span className="centreAlign"><FaSass /> Sass</span></h4>
        <h4><span className="centreAlign"><SiMicrosoftsharepoint /> SharePoint</span></h4>
      <h4><span className="centreAlign"><FaTasks /> Power Automate</span></h4>
      <h4><span className="centreAlign"><FaMagic /> PowerApps</span></h4>
      <h4><span className="centreAlign"><GiPowerLightning /> Power Platform</span></h4>
      <h4><span className="centreAlign"><SiMongodb /> MERN Stack</span></h4>
      </div>
    </div>


    </div>
  );
};

export default Card;
