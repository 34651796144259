// SectionComponent.js
import React from 'react';
import { FaReact, FaAngular, FaHtml5, FaNodeJs, FaCss3, FaVuejs, FaBootstrap, FaJsSquare, FaSass } from 'react-icons/fa';
import { TbBrandNextjs } from "react-icons/tb";
import { Link } from 'react-router-dom';


const AboveFooter = () => {
  return (
    <div>
    <div className="section-container wow fadeInUp" style={{textAlign:'center'}}>
        <div className='AboveFooter'>
        <p className="main-heading">Let's Make Your Business Shine</p>
        <p className="sub-heading">At Bizzard, we're not just building websites – we're building success stories. Let us empower your business with cutting-edge digital solutions that drive growth, enhance efficiency, and unlock new opportunities. Together, we'll make your business shine brighter than ever before.</p>
      <div className="button-container" style={{justifyContent:'center'}}>
        <Link to='/call'><button className="centered-button-inverted">Book a Call</button></Link>
      </div>
      </div>
      </div>
      <div id="scroller1">
     
      <div id="scroller-in1">
        <h4><span className="centreAlign"><FaReact /> React</span></h4>
        <h4><span className="centreAlign"><TbBrandNextjs /> Next.js</span></h4>
        <h4><span className="centreAlign"><FaAngular /> Angular</span></h4>
        <h4><span className="centreAlign"><FaHtml5 /> HTML</span></h4>
        <h4><span className="centreAlign"><FaNodeJs /> Node.js</span></h4>
        <h4><span className="centreAlign"><FaCss3 /> CSS</span></h4>
        <h4><span className="centreAlign"><FaVuejs /> Vue.js</span></h4>
        <h4><span className="centreAlign"><FaBootstrap /> Bootstrap</span></h4>
        <h4><span className="centreAlign"><FaJsSquare /> JavaScript</span></h4>
        <h4><span className="centreAlign"><FaSass /> Sass</span></h4>
        <h4><span className="centreAlign"><FaReact /> React</span></h4>
        <h4><span className="centreAlign"><TbBrandNextjs /> Next.js</span></h4>
        <h4><span className="centreAlign"><FaAngular /> Angular</span></h4>
        <h4><span className="centreAlign"><FaHtml5 /> HTML</span></h4>
        <h4><span className="centreAlign"><FaNodeJs /> Node.js</span></h4>
        <h4><span className="centreAlign"><FaCss3 /> CSS</span></h4>
        <h4><span className="centreAlign"><FaVuejs /> Vue.js</span></h4>
        <h4><span className="centreAlign"><FaBootstrap /> Bootstrap</span></h4>
        <h4><span className="centreAlign"><FaJsSquare /> JavaScript</span></h4>
        <h4><span className="centreAlign"><FaSass /> Sass</span></h4>
        <h4><span className="centreAlign"><FaReact /> React</span></h4>
        <h4><span className="centreAlign"><TbBrandNextjs /> Next.js</span></h4>
        <h4><span className="centreAlign"><FaAngular /> Angular</span></h4>
        <h4><span className="centreAlign"><FaHtml5 /> HTML</span></h4>
        <h4><span className="centreAlign"><FaNodeJs /> Node.js</span></h4>
        <h4><span className="centreAlign"><FaCss3 /> CSS</span></h4>
        <h4><span className="centreAlign"><FaVuejs /> Vue.js</span></h4>
        <h4><span className="centreAlign"><FaBootstrap /> Bootstrap</span></h4>
        <h4><span className="centreAlign"><FaJsSquare /> JavaScript</span></h4>
        <h4><span className="centreAlign"><FaSass /> Sass</span></h4>
        <h4><span className="centreAlign"><FaReact /> React</span></h4>
        <h4><span className="centreAlign"><TbBrandNextjs /> Next.js</span></h4>
        <h4><span className="centreAlign"><FaAngular /> Angular</span></h4>
        <h4><span className="centreAlign"><FaHtml5 /> HTML</span></h4>
        <h4><span className="centreAlign"><FaNodeJs /> Node.js</span></h4>
        <h4><span className="centreAlign"><FaCss3 /> CSS</span></h4>
        <h4><span className="centreAlign"><FaVuejs /> Vue.js</span></h4>
        <h4><span className="centreAlign"><FaBootstrap /> Bootstrap</span></h4>
        <h4><span className="centreAlign"><FaJsSquare /> JavaScript</span></h4>
        <h4><span className="centreAlign"><FaSass /> Sass</span></h4>
      </div>
    </div>
    </div>
  );
};

export default AboveFooter;